
<template>
<div class="pb-4">
  <div v-if="!isLoading" class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Subscription Item</h5>
      <div v-if="subscription">
        <div class="card mb-5">
          <div class="card-body">
            <div class="row gy-3 justify-content-between align-items-start">
              <div class="col-10 col-sm-8">
                <div class="friendly-id mb-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clipboard">
                    <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                    <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                    </svg>
                    {{subscription.friendly_id}}
                </div>
              </div>
              <div class="col-2 col-sm-4 text-sm-end">
                <div class="dropdown no-arrow">
                  <button class="btn btn-sm btn-light dropdown-toggle" type="button" role="button" 
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="ri-more-2-fill"></i>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <router-link :to="`/users/${subscription.user.id}`" class="dropdown-item">
                      View user </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row gy-3">
              <div class="col-12">
                <strong class="me-1">Status:</strong>
                <span v-html="statusBadge(subscription.status)"> </span>
              </div>
              <div class="col-12" v-if="subscription.payment_method">
                <payment-method-card :item="subscription.payment_method"/>
              </div>
              <div class="col-12" v-if="subscription.cancel_at_period_end">
                <div class="alert alert-warning" role="alert">
                  <strong>Note:</strong> This subscription is set to cancel at the end of current period
                  <br /> Period ends on <strong class="me-3">{{$filters.date_time(subscription.current_period_end, 2)}}</strong>
                </div>
              </div>
              <div class="col-12">
                Renewable Amount: <strong>${{$filters.money_format(subscription.amount)}} USD  per {{subscription.interval}} </strong> 
              </div>
              <div class="col-sm-6">
                Period start date: <strong>{{$filters.date_time(subscription.current_period_start, 1)}}</strong>
              </div>
              <div class="col-sm-6">
                Period end date: <strong>{{$filters.date_time(subscription.current_period_end, 1)}}</strong>
              </div>
              <div class="col-12">
                <hr>
                <h6 class="pb-2">Coverage items</h6>
                <div v-for="(item, ctIndex) in subscription.items" :key="ctIndex" class="mb-2">
                  <router-link :to="`/items/${item.id}`" >{{item.title}}</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h5 class="mb-3">Invoice History</h5>
        <div class="card mb-5">
          <div class="card-body">
            <invoice-table :items="subscription.invoices" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <is-loading v-else  />
</div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import PaymentMethodCard from '@/components/cards/PaymentMethodCard.vue'
import InvoiceTable from '@/components/tables/InvoiceTable.vue'

export default {
  components: {
    IsLoading,
    PaymentMethodCard,
    InvoiceTable
  },
  data(){
    return {
        isLoading: true,
        subscription: null
    }
  },
  watch: {
    '$route.params.subId': function (id){
      if(id) this.fetchSubscription()
    },
  },
  methods:{
    fetchSubscription(){
        this.isLoading = true
        this.$http.get(`/subscriptions/${this.$route.params.subId}/show`)
        .then((response) => {
            this.isLoading =  false
            if(response.data.success){
              this.subscription = response.data.data
            }else{
              this.$router.push({path: '/error-404'})
            }
        })

    }
  },
  created(){
    this.fetchSubscription()
  }
}

</script>

<style lang="scss">

</style>
